import { styled } from "styled-components";
import { User } from "../models/User.model";
import {
  LeaderboardDivider,
  LETTER_LENGTH,
  UserCard,
  UserCardTitle,
} from "./Common";
import { useContext } from "react";
import { ActiveUserContext } from "../utils/contexts";

interface LeaderboardProps {
  activeUsers: User[];
  gameOver: boolean;
}

const LeaderboardContainer = styled.div<{ width: number }>`
  margin-top: 50px;
  display: flex;
  overflow-x: auto;
  margin-bottom: 10px;
  justify-content: ${({ width }) =>
    window.innerWidth > width ? "center" : "left"};
`;

const HiddenAnswer = styled.div<{ length: number }>`
  width: ${({ length }) => length * LETTER_LENGTH}px;
  height: 24px;
  background-color: ${(props) => props.theme.TEXT};
  margin: auto;
`;

const Leaderboard = ({ activeUsers, gameOver }: LeaderboardProps) => {
  const userId = useContext(ActiveUserContext);

  return (
    <LeaderboardContainer width={activeUsers.length * LETTER_LENGTH * 12}>
      {activeUsers.map((u) => (
        <UserCard key={u.userId} width={LETTER_LENGTH * 12}>
          <UserCardTitle $self={u.userId === userId}>{u.userId}</UserCardTitle>
          <LeaderboardDivider />
          {u.guesses.map((g) =>
            gameOver || u.userId === userId ? (
              <div key={g}>{g}</div>
            ) : (
              <HiddenAnswer key={g} length={g.length} />
            )
          )}
        </UserCard>
      ))}
    </LeaderboardContainer>
  );
};

export default Leaderboard;
