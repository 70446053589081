import axios from "axios";
import { Clue } from "../models/Clue.model";

const SERVER = process.env.REACT_APP_SERVER_BASE;

export const getClue = async (date: string): Promise<Clue> => {
  const response = await axios.get<Clue>(`${SERVER}/clue/${date}`);

  if (response.status !== 200) throw new Error("Clue not found");
  else return response.data;
};
