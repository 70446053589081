import { useContext, useEffect, useState } from "react";
import { Button, Tab } from "./Common";
import Title from "./Title";
import { Clue } from "../models/Clue.model";
import { getDateString } from "../models/Utils";
import { getClue } from "../api/Clue.api";
import { ActiveUserContext } from "../utils/contexts";
import { User } from "../models/User.model";
import { getUsersByDate, updateUser } from "../api/User.api";
import Leaderboard from "./Leaderboard";
import { styled } from "styled-components";

interface HomePageProps {
  navigate: (t: Tab) => void;
}

const NameInput = styled.input`
  font-family: monospace;
  display: block;
  margin: 20px auto;
  font-size: 24px;
  border: 5px solid ${(props) => props.theme.DISABLED};
  border-radius: 5px;
  padding: 8px 8px 8px 18px;
  color: ${(props) => props.theme.TEXT};
  background-color: ${(props) => props.theme.BACKGROUND};
  letter-spacing: 10px;

  &:focus {
    outline: 0;
    border: 5px solid ${(props) => props.theme.HIGHLIGHT};
  }
`;

const HomePage = ({ navigate }: HomePageProps) => {
  const userId = useContext(ActiveUserContext);
  const [clueData, setClueData] = useState<Clue | null>(null);
  const [guess, setGuess] = useState<string>("");
  const [pastGuesses, setPastGuesses] = useState<string[]>([]);
  const [guessResult, setGuessResult] = useState<string>("");
  const [allUsers, setAllUsers] = useState<User[] | null>(null);
  const [activeUsers, setActiveUsers] = useState<User[] | null>(null);
  const [gameOver, setGameOver] = useState<boolean>(false);

  useEffect(() => {
    const today = getDateString(new Date());
    // const today = "2024-10-13";
    getClue(today).then((res) => {
      setClueData(res);
      getUsersByDate(today).then((users) => {
        const usersWithGuesses = users.filter((u) => u.guesses.length > 0);
        setActiveUsers(usersWithGuesses);
        setAllUsers(users);
        const signedInUser = usersWithGuesses.find((u) => u.userId === userId);
        if (signedInUser) {
          setPastGuesses(signedInUser.guesses);
          if (signedInUser.guesses.includes(res.answer)) {
            setGameOver(true);
          }
        }
      });
    });
  }, [userId]);

  const onGuessChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (clueData) {
      setGuess(
        e.target.value.toLocaleUpperCase().substring(0, clueData.answer.length)
      );
    }
  };

  const onCheck = () => {
    const upperCaseGuess = guess.toLocaleUpperCase();

    if (
      allUsers &&
      clueData &&
      upperCaseGuess.length === clueData.answer.length &&
      !gameOver
    ) {
      console.log("search:", upperCaseGuess);
      if (upperCaseGuess === clueData.answer) {
        setGuessResult(`CORRECT: ${upperCaseGuess}`);
      } else {
        setGuessResult(`WRONG: ${upperCaseGuess}`);
      }

      const signedInUser = allUsers.find((u) => u.userId === userId);

      const payload: User = {
        userId: userId,
        guesses: signedInUser
          ? [...signedInUser.guesses, upperCaseGuess]
          : [upperCaseGuess],
        date: clueData.date,
      };

      updateUser(payload).then(() => {
        const today = getDateString(new Date());
        getUsersByDate(today).then((users) => {
          const usersWithGuesses = users.filter((u) => u.guesses.length > 0);
          setActiveUsers(usersWithGuesses);
          setAllUsers(users);
          const signedInUser = usersWithGuesses.find(
            (u) => u.userId === userId
          );
          if (signedInUser) {
            setPastGuesses(signedInUser.guesses);
            if (signedInUser.guesses.includes(clueData.answer)) {
              setGameOver(true);
            }
          }
        });
      });
    }
  };

  return (
    <>
      <Title />
      {clueData ? (
        <>
          <div>{clueData.clue.map((c) => c.text).join(" ")}</div>
          <br />
          <div>Length: {clueData.answer.length}</div>
          <NameInput
            autoFocus
            value={guess}
            onChange={onGuessChange}
            disabled={gameOver}
            size={clueData.answer.length}
          />
          <Button
            onClick={() => onCheck()}
            disabled={guess.length !== clueData.answer.length || gameOver}
          >
            Check
          </Button>
          <br />
          <br />
          <div>{guessResult}</div>
          <br />
          {pastGuesses.map((g) => (
            <div>{g}</div>
          ))}
          {activeUsers && (
            <Leaderboard activeUsers={activeUsers} gameOver={gameOver} />
          )}
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default HomePage;
